<template>
    <v-app id="bg_question">
         <div id="layout_top_question">
            
        </div>

        <div class="one_content d-flex flex-column justify-space-between align-center pa-10">
        <v-row align="center" justify="space-around">
            <v-col cols="12" class="d-flex flex-column align-center pt-10">
                <v-row justify="center">
                    <v-dialog
                    v-model="dialog"
                    persistent
                    max-width="290">
                    <v-card>
                        <p class="text-center t_submit">
                            Penasaran sama hasilnya ? <br> Kenalan dulu yuk!
                        </p>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                     <form @submit.prevent="submit">
                                        <div class="inputWithIcon">
                                            <input type="text"  placeholder="Name" v-model="name" required>
                                            <font-awesome-icon class="icon" required icon="user-circle" size="lg"></font-awesome-icon>
                                        </div>
                                        <div class="inputWithIcon">
                                            <input type="email" placeholder="Email" v-model="email" required>
                                            <font-awesome-icon class="icon" required icon="envelope" size="lg"></font-awesome-icon>
                                        </div>
                                        <div class="inputWithIcon">
                                            <input type="number" placeholder="Phone" v-model="phone_number" required>
                                            <font-awesome-icon class="icon" icon="phone" size="lg"></font-awesome-icon>
                                        </div>

                                        <b-button
                                            type="submit"
                                            pill
                                            size="sm"
                                            class="mt-5 btn_submit white--text">                                            <span class="text_btn">
                                                SUBMIT
                                            </span>
                                        </b-button>

                                        </form>

                                        <p class="text-center mt-5" style="font-size:6px;font-weight:500; font-style:italic;line-height: 9px;">
                                            Disclaimer: Data kamu akan tersimpan dengan aman oleh kami dan tidak akan disalahgunakan dan tidak tersebar ke pihak lain.
                                        </p>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                    </v-dialog>
                </v-row>
            </v-col>
        </v-row>
        </div>
    </v-app>
</template>

<style>
    @font-face {
        font-family: Poppins;
        src: url('../../assets/fonts/Poppins-Medium.ttf');
    }
    #bg_question {
        width: 100%;
        height: 100%;
        background: #FFB61D;
    }
    .one_content{
        z-index:6;
        margin-top:90px;
    }
    .t_soal{
        font-weight: 700;
        font-style: normal;
        font-size: 18px;
    }
    .t_result{
        font-weight: 400;
        font-style: italic;
        font-size:9px;
    }
    .text_btn{
        font-weight: 600;
        font-style: bold;
        font-size:12px;
    }
    .btn_submit{
        width: 100px;
        margin-left:auto;
        margin-right:auto;
        display:block;
        background: #F37679 !important;
        border: 0px solid #F37679;
    }
    .t_submit{
        padding-top:30px;
        margin-left:10px;
        font-weight: 700;
        font-style: bold;
        color: #F37679;
        font-size: 18px;
    }
    #layout_top_question {
        position: fixed;
        width: 100%;
        height: 100%;
        background: url('../../assets/images/bg.png') no-repeat;
        background-size: 100%;  
    }
    @media (min-width : 1000px) {
        #layout_top_question{
            width : 100%;
            height : 100%;
            position :fixed;
        }
    }
    .v-dialog{
        border-radius:43px !important;
    }
    .v-sheet.v-card{
        border-radius: 43px !important;
    }
    input[type="text"] {
        width: 100%;
        border: 1px solid #BBA5AA;
        border-radius: 43px;
        outline: none;
        padding: 8px;
        box-sizing: border-box;
        transition: 0.3s;
        margin-bottom:20px;
    }
    .inputWithIcon input[type="text"] {
        padding-left: 40px;
    }
    input[type="email"] {
        width: 100%;
        border: 1px solid #BBA5AA;
        border-radius: 43px;
        outline: none;
        padding: 8px;
        box-sizing: border-box;
        transition: 0.3s;
        margin-bottom:20px;
    }
    .inputWithIcon input[type="email"] {
        padding-left: 40px;
    }
    input[type="number"] {
        width: 100%;
        border: 1px solid #BBA5AA;
        border-radius: 43px;
        outline: none;
        padding: 8px;
        box-sizing: border-box;
        transition: 0.3s;
        margin-bottom:20px;
    }
    .inputWithIcon input[type="number"] {
        padding-left: 40px;
    }

    .inputWithIcon {
        position: relative;
    }

    .inputWithIcon .icon {
        position: absolute;
        left: 0;
        top: 10px;
        color: #aaa;
        margin-left:15px;
        transition: 0.3s;
    }


</style>

<script>
export default {
  name: 'SubmitQuestion',
  data () {
      return {
        dialog: true,
        name: '',
        email : '',
        phone_number: '',
      }
  },
  methods: {
    changeColor(){
        var metaThemeColor = document.querySelector("meta[name=theme-color]");
            metaThemeColor.setAttribute("content", "#FFB61D");
    },

    checkAnswer(){
        var a = 0;
        var b = 0;
        var c = 0;
        for (var i = 1; i<8; i++) {
            if(localStorage.getItem(i) == "0"){
                a += 1
            }else if(localStorage.getItem(i) == "1"){
                b += 1
            }else if(localStorage.getItem(i) == "2"){
                c += 1
            }
        }

        if(a > b && a > c){
            this.$router.push({ name: 'result-a'})
        }else if(b > a && b > c){
            this.$router.push({ name: 'result-b'})
        }else if(c > a && c > b){
            this.$router.push({ name: 'result-c'})
        }else if(a === b){
            this.$router.push({ name: 'result-a'})
        }else if(b === c){
            this.$router.push({ name: 'result-b'})
        }else if(a === c){
            this.$router.push({ name: 'result-a'})
        }


    },
    
    submit(){
        let formData = {
            'name' : this.name,
            'email' : this.email,
            'phone_number' : this.phone_number
        }
        this.$axios.post('apiv1/submit_quiz', formData)
        .then((response) => {
            console.log(response.data.success)
            if(response.data.success){
                    this.$notify({
                        title: "Berhasil",
                        type: 'success',
                        text: response.data.message
                    });

                    this.checkAnswer()
            }else{
                this.$notify({
                    title: "Gagal",
                    type: 'error',
                    text: response.data.message
                });
            }
        })
        .catch((error) => {
            console.log(error)
        })
    }
  },
  created() {
      this.changeColor();
  },
}
</script>
